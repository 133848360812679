import $ from 'jquery';

$(() => {
  if (!Modernizr.objectfit) {
    $('.objectfitter').each(function () {
      var $container = $(this),
        imgUrl = $container.find('img').attr('data-src');
      if (imgUrl) {
        $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('object-fitter--no-support');
      }
    });
  } else {
    $('.objectfitter').each(function () {
      $(this).addClass('object-fitter--support');
    });
  }
});
