import $ from 'jquery';
import 'jquery.mmenu';
import 'jquery.mmenu/dist/jquery.mmenu.css';

class Mmenu {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.initMmenu();
    });

    // $.ready(() => );
  }

  initMmenu() {
    /*
     TODO convert to javascript... from blade
     @if(function_exists('icl_get_languages'))
     <?php $languages = icl_get_languages('skip_missing=0&orderby=code'); ?>
     @if(!empty($languages))
     mmlanguageswicher += '<div class="language-switcher">';
     mmlanguageswicher += '<ul id="language-switcher-mm">';
     @foreach($languages as $language)
     mmlanguageswicher += '<li class="@if($language["active"]) active @endif language-switcher-{{ $language['code'] }}"><a href="{{ $language['url'] }}">';
     mmlanguageswicher += '  {{ icl_disp_language($language['translated_name']) }}';
     mmlanguageswicher += '</a></li>';
     @endforeach
     mmlanguageswicher += '</ul>',
     mmlanguageswicher += '</div>',
     @endif
     @endif
     */

    const template = document.querySelector('#mmenu-template').innerHTML;

    $('#wlymmenu').mmenu({
      extensions: ['fx-listitems-fade', 'border-offset', 'pagedim-black', 'theme-black'],
      navbars: [
        {
          position: 'bottom',
          content: [template],
        },
      ],
    });
  }
}

export default new Mmenu();
