import { RunThemeExtensions } from 'wly-theme-extensions';
import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';
import Shop from '@/js/vue/components/Shop.vue';

RunThemeExtensions({
  vue: {
    components: {
      'wly-youtube': YoutubePlayer,
      'wly-shop': Shop,
    },
  },
});
