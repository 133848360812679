import $ from 'jquery';
import 'lazysizes';

$(() => {
  lazySizes.init();

  // Scroll to
  $(document).on('click', 'a[href]:not([href^="mailto\\:"], [href$="\\#"])', function (event) {
    if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu') {
      event.preventDefault();
      $('html, body').animate(
        {
          scrollTop: $($.attr(this, 'href')).offset().top - $('header').height(),
        },
        1000
      );
    }
  });

  // Akkordeon
  $('.wly-accordeon__title').click(function () {
    $(this).parent().toggleClass('active');
    $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

    $('.wly-accordeon__item').each(function () {
      if ($(this).hasClass('active')) {
        $(this).children('.wly-accordeon__content').slideDown();
      } else {
        $(this).removeClass('active');
        $(this).children('.wly-accordeon__content').slideUp();
      }
    });
  });

  // Navigation Touch Geräte
  $('ul.navbar-nav > li.parent > a').on('touchend', function (e) {
    if (!$(this).parent().hasClass('hovered')) {
      e.preventDefault();
      $(this).parent().toggleClass('hovered');
      $(this).parent().siblings().removeClass('hovered');
    }
  });

  let B = (function (raw) {
    try {
      return JSON.parse(raw) || [];
    } catch (err) {
      return [];
    }
  })(window.localStorage.getItem('ac-basket'));
  document.querySelector('body').dataset.productsInCart = !!B.length;
});
