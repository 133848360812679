<template>
  <div class="v-shop" ref="main">
    <slot v-bind="{ products, errors, productsInBasket, totalPrice, onSubmit, currentStep, showSecondStep: showSecondStep, showFirstStep: showFirstStep }" />
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Object,
    },
  },
  data: () => ({
    main: null,
    currentStep: 1,
    errors: {},
    products: window.products,
  }),
  mounted() {
    let A = (function (raw) {
      try {
        return JSON.parse(raw) || [];
      } catch (err) {
        return [];
      }
    })(window.localStorage.getItem('ac-basket'));
    A = A.filter((item) => {
      return !!this.products.find((product) => product.id === item.id);
    });
    let B = this.products;
    this.products = A.concat(B.filter((bo) => A.every((ao) => ao.id !== bo.id)));

    this.$nextTick(() => {
      if (location.href.includes('captcha=error')) {
        this.showSecondStep();
        this.errors.captcha = this.labels.errors.captcha;
        window.history.pushState({}, document.title, location.href.split('?')[0]);
      } else {
        this.showFirstStep();
      }
    });
  },
  computed: {
    productsInBasket() {
      let B = (function (raw) {
        try {
          return JSON.parse(raw) || [];
        } catch (err) {
          return [];
        }
      })(window.localStorage.getItem('ac-basket'));

      B = B.filter((item) => {
        let product = this.products.find((product) => product.id === item.id);
        if (product) {
          if (product.amount === 0 || product.amount === '0') {
            return false;
          }
        }
        return true;
      });

      let A = this.products.filter(function (product) {
        return product.amount > 0;
      });
      return A.concat(B.filter((bo) => A.every((ao) => ao.id !== bo.id)));
    },
    totalPrice() {
      let B = (function (raw) {
        try {
          return JSON.parse(raw) || [];
        } catch (err) {
          return [];
        }
      })(window.localStorage.getItem('ac-basket'));

      B = B.filter((item) => {
        let product = this.products.find((product) => product.id === item.id);
        if (product) {
          if (product.amount === 0 || product.amount === '0') {
            return false;
          }
        }
        return true;
      });

      let A = this.products.filter(function (product) {
        return product.amount > 0;
      });
      let products2 = A.concat(B.filter((bo) => A.every((ao) => ao.id !== bo.id)));

      return products2.reduce(function (total, item) {
        if (!isNaN(parseFloat(item.price))) {
          return total + parseFloat(item.price) * item.amount;
        }
        return total;
      }, 0);
    },
  },
  watch: {
    products: {
      handler: function (after, before) {
        let B = (function (raw) {
          try {
            return JSON.parse(raw) || [];
          } catch (err) {
            return [];
          }
        })(window.localStorage.getItem('ac-basket'));

        B = B.filter((item) => {
          let product = this.products.find((product) => product.id === item.id);
          if (product) {
            if (product.amount === 0 || product.amount === '0') {
              return false;
            }
          }
          return true;
        });

        let A = after.filter(function (product) {
          return product.amount > 0;
        });

        document.querySelector('body').dataset.productsInCart = !!A.concat(B.filter((bo) => A.every((ao) => ao.id !== bo.id))).length;
        window.localStorage.setItem('ac-basket', JSON.stringify(A.concat(B.filter((bo) => A.every((ao) => ao.id !== bo.id)))));

        this.showFirstStep();
      },
      deep: true,
    },
  },
  methods: {
    showFirstStep() {
      this.currentStep = 1;

      this.$nextTick(() => {
        M.FormSelect.init(this.$refs.main.querySelectorAll('.select__wrapper > select'));
      });
    },
    showSecondStep() {
      this.currentStep = 2;

      this.$nextTick(() => {
        M.FormSelect.init(this.$refs.main.querySelectorAll('.select__wrapper > select'));
        window.grecaptcha?.render(this.$el.querySelector('.g-recaptcha'));
      });
    },
    onSubmit: async function (e) {
      const form = this.$el.querySelector('form');
      const data = new FormData(form);
      const values = Object.fromEntries(data);

      this.errors = {};

      if (!values.salutation) {
        this.errors.salutation = this.labels.errors.required;
      }

      if (values.zip?.length !== 4) {
        this.errors.zip = this.labels.errors.zip;
      }

      if (Object.keys(this.errors).length) {
        e.preventDefault();
      }
    },
    addProduct: function (product) {
      product.amount++;
    },
    removeProduct: function (product) {
      if (product.amount > 0) {
        product.amount--;
      }
    },
  },
};
</script>
